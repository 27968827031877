/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import "./src/styles/style.scss";

export const onRouteUpdate = ({ location, prevLocation }) => {
  //console.log('in onRouteUpdate');
  //console.log("new pathname", location.pathname);
  //console.log("old pathname", prevLocation ? prevLocation.pathname : null);
  //window.scrollTo(0, 0);
  //console.log('activatig google ads');
  //var adsbygoogle;
  //(adsbygoogle = window.adsbygoogle || []).push({});
  //console.log({ adsbygoogle });
};

export const onInitialClientRender = () => {
  //console.log('in onInitialClientRender')
  //window.scrollTo(0, 0);
};

export const shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {
  const { pathname, search, hash } = location;
  //console.log('in shouldUpdateScroll pathname='+pathname)
  //var splitpath = pathname.split('/');
  //console.log('search='+search);
  //console.log('hash='+hash);

  if ((pathname.startsWith("/recipes/view/") || pathname === "/") && search === "" && hash === "") {
    //if (search === '' && hash === '') {
    //console.log('scrolling to zero')
    //var top  = window.pageYOffset || document.documentElement.scrollTop;
    //console.log('top before='+top)
    //window.scrollTo(0, 0);
    //var top  = window.pageYOffset || document.documentElement.scrollTop;
    //console.log('top after='+top)
    // 100ms delay may fix scrolling issue on firefox
    if (false) {
      setTimeout(function () {
        const pagestate_id = document.getElementById("page-state");
        const pagestate = pagestate_id.getAttribute("value");
        console.log("pagestate=" + pagestate);

        if (pagestate === "new") {
          var top = window.pageYOffset || document.documentElement.scrollTop;
          console.log("top before2=" + top);
          window.scrollTo(0, 0);
          top = window.pageYOffset || document.documentElement.scrollTop;
          console.log("top after2=" + top);
          pagestate_id.setAttribute("value", "loaded");
        }
      }, 100);
    }

    if (false) {
      document.onload = () => {
        var top = window.pageYOffset || document.documentElement.scrollTop;
        console.log("3 top before=" + top);
        window.scrollTo(0, 0);
        top = window.pageYOffset || document.documentElement.scrollTop;
        console.log("3 top after=" + top);
      };
    }

    if (true) {
      //document.onload = () => {
      setTimeout(function () {
        //  = window.pageYOffset || document.documentElement.scrollTop;
        //console.log('top before2='+top)
        window.scrollTo(0, 0);
        //top  = window.pageYOffset || document.documentElement.scrollTop;
        //console.log('top after2='+top)
      }, 100);
      // }
    }

    //const pagestate = document.getElementById("page-state");
    //console.log('pagestate='+pagestate.getAttribute("value"));

    //const main = document.getElementById("main-container");
    //var top = main.scrollY;
    //console.log('main top='+top);

    return false;
  }
};

export { default as wrapRootElement } from "./src/redux-wrapper";

//window.addEventListener("scroll", function(event) {
//  var top = this.scrollY,
//      left =this.scrollX;
//  console.log('window top='+top);
//}, false);

//const mainid = document.getElementById("main-container");
//if (false) {
//if (mainid !== undefined) {
//  mainid.addEventListener("scroll", function(event) {
//    var top = this.scrollY,
//        left =this.scrollX;
//    console.log('main-container top='+top);
//  }, false);
//}
